<template>
  <div class="find_structure">
    <div class="head_search">
      <strong class="screen_out">조직원 검색</strong>
      <div class="info_search">
        <!-- 입력시 search_on 클래스 추가하여 자동완성 노출 -->
        <input
          id="defaultSearch"
          v-model="keyword"
          type="text"
          class="inp_comm inp_search"
          title="검색하기"
          autocomplete="off"
          :placeholder="placeholderSearch"
          @input="keyword = $event.target.value"
          @keyup="searchDebounce()"
        />
        <!-- @input="keyword = $event.target.value" -->
        <span class="ico_account ico_search" />
      </div>
    </div>
    <!-- // head_search -->
    <ul id="orgTree" class="list_structure">
      <!-- <comm-org-tree-item
        v-if="renderComponent"
        :item="orgTreeData"
        :isSearch="isSearch"
        :keyword="keyword"
        :searchTargetEmployee="searchTargetEmployee"
        :depth="1"
        :cmpDeptCode="cmpDeptCode">
      </comm-org-tree-item> -->
      <CommOrgTreeItem
        v-if="renderComponent"
        :item="orgTreeData"
        :isSearch="isSearch"
        :keyword="keyword"
        :searchTargetEmployee="searchTargetEmployee"
        :depth="1"
        :cmpDeptCode="cmpDeptCode"
      />
    </ul>
    <!-- // list_structure -->
  </div>
</template>

<script>
import CommOrgTreeItem from "./CommOrgTreeItem.vue";
import { mapState } from "vuex";
import ApiService from "@/services/ApiService";

export default {
  name: "CommOrgTree",
  components: {
    CommOrgTreeItem,
  },
  props: {
    placeholderSearch: {
      type: String,
      default: "입력",
    },
  },
  data() {
    return {
      orgTreeData: {},
      keyword: "",
      isSearch: false,
      renderComponent: true,
      cmpDeptCode: "",
      searchTargetEmployee: [],
    };
  },
  computed: {
    ...mapState("CommStore", {
      service: (state) => state.service,
    }),
  },
  created() {
    this.getRootDeptChildList();
  },
  methods: {
    searchDebounce: _.debounce(function () {
      this.keyup();
    }, 500),
    async keyup(key) {
      if (this.keyword.length > 1) {
        this.isSearch = true;

        // 검색어에 해당하는 사원들 리스트를 하위 컴포넌트에 넘겨준다.
        const path = `${this.$apiPath.APRVL_ORGDEPT_TREE}?empInfo=${this.keyword}`;
        const res = await ApiService.shared.getData(path);

        this.searchTargetEmployee = res.data;

        this.forceRerender();
      }
      //  else if (this.keyword.length === 2) {
      //   this.isSearch = false;
      //   this.forceRerender();
      // }
      else {
        this.isSearch = false;
        this.getRootDeptChildList();
      }
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    async getRootDeptChildList() {
      const params = {
        deptCode: "#",
        deptOnly: true,
      };

      const rootRes = await ApiService.shared.get(this.$apiPath.APRVL_ORGDEPT_TREE, { params });

      console.log("rootRes", rootRes);
      if (!rootRes.data) return;
      const root = rootRes.data.pop();
      root.isOpen = true;

      this.orgTreeData = root;
      this.cmpDeptCode = root.deptCode;
      this.forceRerender();

      //   // axios.post(
      //   //   '/api/common/selectOrgTreeByRootDeptCode',
      //   //   {deptCode: this.service.rootDeptCode}
      //   // ).then(res => {
      //   //   this.orgTreeData = res.data
      //   //   this.cmpDeptCode = res.data.id
      //   //   this.forceRerender()
      //   // }).catch(error => {
      //   //   console.log(error.response)
      //   // })
      // }
    },
  },
};
</script>
<style scoped>
.list_structure {
  overflow-y: auto;
  width: 340px;
  padding: 6px 10px;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
}
</style>
