<template>
  <div class="layer_body">
    <div class="box_structure" :class="type_one">
      <p v-show="isShowCaution" class="txt_caution" v-html="caution" />
      <p v-if="tipDesc" class="desc_tip">
        {{ tipDesc }}
      </p>
      <comm-org-tree />
      <!-- add_groupinfo (조직원 1명만 선택인 레이어에서는 add_groupinfo 구조 삭제) -->
      <comm-org-listbox
        v-if="isShowListbox"
        :employeeList="employeeList"
        :validate="validateFunc"
        :isArrowDupliEmp="isArrowDupliEmp"
        :spliceAddIndex="spliceAddIndex"
      />
      <!-- // add_groupinfo -->
    </div>
    <div class="layer_foot">
      <!-- // box_structure -->
      <div class="wrap_btn">
        <button type="button" class="btn_medium btn_fourthly" @click.prevent="beforeClose">
          취소
        </button>
        <button type="button" class="btn_medium btn_primary" @click.prevent="attachEmployee">
          적용
        </button>
      </div>
      <!-- <slot></slot> X(창닫기) -->
    </div>
  </div>
</template>

<script>
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import CommInpSearch from "@/_approval/components/common/CommInpSearch";
import CommOrgListbox from "@/_approval/components/common/CommOrgListbox";
import CommOrgTree from "@/_approval/components/common/CommOrgTree";
import { mapState } from "vuex";
import { commConst } from "@/_approval/const/const.js";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "CommPopOrganization",
  components: {
    CommInpSearch,
    CommOrgListbox,
    CommOrgTree,
  },
  mixins: [CommLayerMixin],
  props: {
    tipDesc: String,
    orgType: Number,
    caution: String,
    employe: Object,
    validateFunc: {
      type: Function,
      default: () => true,
    },
    employeeList: {
      type: Array,
      default: () => [],
    },
    alertMsg: String,
    isArrowDupliEmp: {
      type: Boolean,
      default: false,
    },
    spliceAddIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      searchType: "",
      keyword: "",
      isShowCaution: false,
    };
  },
  created() {
    this.$EventBus.$on("tree-select-item", this.selectTreeItem);
    if (this.caution) {
      this.isShowCaution = true;
    }
  },
  computed: {
    ...mapState("CommStore", {
      login: (state) => state.login,
    }),
    isShowListbox() {
      if (this.orgType === 1) {
        // single select
        return false;
      } else {
        // multi select
        return true;
      }
    },
    type_one() {
      if (this.orgType === 1) {
        // single select
        return "type_one";
      } else {
        // multi select
        return "";
      }
    },
  },
  methods: {
    attachEmployee() {
      if (this.orgType === 1) {
        // single select
        let employee = {};
        employee.empNo = this.employee.id || this.employee.empNo;
        employee.empName = this.employee.name || this.employee.empName;
        employee.accountId = this.employee.accountId;
        employee.deptCode = this.employee.deptCode;
        employee.deptName = this.employee.deptName;

        this._okPopup(employee);
      } else {
        let returnEmpList = [];
        let targetList = document.getElementById("groupPeople").querySelectorAll(".target_name");
        targetList.forEach((target, index) => {
          returnEmpList.push(this.findEmployee(target.dataset.key, this.employeeList));
        });
        if (returnEmpList.length < 1) {
          this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
            text: `조직도에서 승인자를 선택해주세요`,
          });
          return;
        }
        this._okPopup(returnEmpList);
      }
      this.$parent.$destroy();
    },
    beforeClose() {
      this.$parent.$destroy();
      this._closeLayer(1);
    },
    selectTreeItem(item) {
      this.employee = item;
    },
    findEmployee(dataKey, employeeList) {
      let returnEmp = {};
      let displayEmp = "";
      this.employeeList.forEach((emp, index) => {
        if (this.login.cmpCode === commConst.DKT_CMP_CODE) {
          displayEmp = emp.accountId + "(" + emp.empName + ")";
        } else {
          displayEmp = emp.empName + "(" + emp.accountId + ")";
        }
        if (
          emp.empNo + emp.actorFlag + emp.actorState + emp.apprLineFlag + emp.finalApprYn ===
          dataKey
        ) {
          returnEmp = emp;
        }
      });
      return returnEmp;
    },
  },
};
</script>
<style scoped>
.box_structure {
  overflow: hidden;
  width: 640px;
  padding-top: 24px;
  border-top: 1px solid #222;
}
.box_structure.type_one {
  width: auto;
}
.box_structure.type_one .add_groupinfo {
  display: none;
}
.box_structure .desc_tip {
  padding-bottom: 24px;
}
.box_structure .find_structure {
  float: left;
}
.box_structure .head_search {
  font-size: 0;
}
</style>
