<template>
  <li :class="openClass">
    <button v-if="item.isDept" type="button" class="btn_fold" @click.prevent="toggleOpenDept">
      <span class="ico_account">하위메뉴 펼치기 / 접기</span>
    </button>
    <a
      href="javascript:void(0);"
      class="link_txt"
      :class="onClass"
      :data-id="item.id"
      @click.prevent="addOnClass($event)"
      >{{ login.cmpCode | orgTreeNode(item.deptName, item.accountId, item.empName) }}</a
    >
    <ul v-show="item.isOpen" v-if="item.isDept" class="list_category list_team">
      <div v-if="renderComponent">
        <CommOrgTreeItem
          v-for="(child, index) in item.children"
          :key="index"
          :depth="depth + 1"
          :item="child"
          :isSearch="isSearch"
          :keyword="keyword"
          :searchTargetEmployee="searchTargetEmployee"
          :cmpDeptCode="cmpDeptCode"
        />
      </div>
    </ul>
  </li>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ApiService from "@/services/ApiService";

export default {
  name: "CommOrgTreeItem",
  props: {
    depth: Number,
    item: Object,
    eventHub: Object,
    isSearch: Boolean,
    keyword: String,
    cmpDeptCode: String,
    searchTargetEmployee: [Array, Object],
  },
  data() {
    return {
      onClass: "",
      openClass: "",
      renderComponent: true,
      searchEmployee: {},
    };
  },
  computed: {
    ...mapState("CommStore", {
      login: (state) => state.login,
    }),
  },
  // async created () {
  //   if (!this.item.isDept) { return; }

  //   if (this.isSearch) {
  //     await this.appendChildDeptWhichEmployeeBelongs(true);
  //   } else if (this.item.isOpen) {
  //     await this.appendChildDept()
  //   }
  // },
  created() {
    if (!this.item.isDept) {
      return;
    }

    if (this.isSearch) {
      this.appendChildDeptWhichEmployeeBelongs(true);
    } else if (this.item.isOpen) {
      this.appendChildDept();
    }
  },
  methods: {
    /**
     * 특정 직원들이 속한 하위 부서들만 필터링하여 출력한다.
     * @param isAutoOpen 자동으로 부서 카테고리가 열릴지 여부
     * @return {Promise<void>}
     */
    async appendChildDeptWhichEmployeeBelongs(isAutoOpen = false) {
      const params = {
        deptCode: this.item.deptCode,
      };

      const path = `${this.$apiPath.APRVL_ORGDEPT_TREE}?deptCode=${this.item.deptCode}`;
      const res = await ApiService.shared.getData(path);
      const subDepartment = res.data;

      this.forceRerender();

      let departmentsBelongsEmployee = {};
      if (subDepartment) {
        departmentsBelongsEmployee = subDepartment
          .filter((subDept) => {
            if (subDept.isDept) {
              return this.searchTargetEmployee.some((emp) => {
                const splitDeptCode = emp.deptPathCode.split(";");
                // 하위 부서를 비교 해야하므로 depth + 1 을 해준다.
                return splitDeptCode[this.depth + 1] === subDept.deptCode;
              });
            } else {
              return (
                subDept.accountId.includes(this.keyword) || subDept.empName.includes(this.keyword)
              );
            }
          })
          .map((subDept) => {
            if (subDept.isDept) {
              subDept.isOpen = isAutoOpen;
            }
            return subDept;
          });
      }

      this.item.children = departmentsBelongsEmployee;
      this.openDept();
    },
    async appendChildDept() {
      // const res = await axios.get(`${ this.$apiPath.APRVL_ORGDEPT_TREE }?deptCode=${this.item.deptCode}`);
      const res = await ApiService.shared.getData(
        `${this.$apiPath.APRVL_ORGDEPT_TREE}?deptCode=${this.item.deptCode}`,
      );

      this.forceRerender();
      this.item.children = res.data;
      this.openDept();
    },
    selectOrgTree1DepthByKeyword() {
      axios
        .post("/api/common/selectOrgTree1DepthByKeyword", {
          deptCode: this.item.id,
          keyword: this.keyword,
        })
        .then((res) => {
          this.forceRerender();
          this.item.children = res.data;
          this.openDept();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    selectOrgTreeByKeyword() {
      axios
        .post("/api/common/selectOrgTreeByKeyword", {
          deptCode: this.item.id,
          deptPathCode: this.item.deptPathCode,
          keyword: this.keyword,
          deptDepth: this.item.deptDepth,
        })
        .then((res) => {
          this.forceRerender();
          this.item.children = res.data;
          this.openDept();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    toggleOpenDept() {
      if (this.item.isDept) {
        this.item.isOpen = !this.item.isOpen;
      }
      if (this.item.isOpen) {
        if (this.isSearch) {
          this.appendChildDeptWhichEmployeeBelongs(false);
        } else {
          this.appendChildDept();
        }
      } else {
        this.openClass = "";
      }
    },
    addOnClass(event) {
      let onClassList = document.querySelector("#orgTree").querySelectorAll("li > a.link_txt.on");
      if (onClassList) {
        onClassList.forEach((el, index) => {
          el.classList.remove("on");
        });
      }
      event.target.classList.add("on");
      this.$EventBus.$emit("tree-select-item", this.item);
    },
    openDept() {
      if (this.item.isOpen) {
        this.openClass = "open_cate";
      } else {
        this.openClass = "";
      }
    },
    closeDept() {
      this.openClass = "";
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>
<style scoped>
.list_structure li {
  font-size: 0;
  line-height: 0;
}
.list_structure .btn_fold {
  float: left;
  margin: 0 2px 0 -4px;
  padding: 4px;
}
.list_structure .link_txt {
  display: block;
  overflow: hidden;
  height: 24px;
  font-size: 14px;
  line-height: 26px;
  color: #222;
}
.list_structure .list_team .link_txt.on {
  background: #f9f9f9;
  color: #5551ce;
  text-decoration: underline;
}
.list_structure .list_team .link_txt.on:after {
  display: block;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
  content: "선택됨";
}
.list_structure .list_team .link_txt {
  padding-left: 10px;
  color: #999;
}
.list_structure .btn_fold .ico_account {
  width: 16px;
  height: 16px;
  background-position: -140px -50px;
} /* 최상위 펼치기 전 + */
.list_structure .open_cate > .btn_fold .ico_account {
  background-position: -160px -50px;
} /* 최상위 펼친 후 - */
.list_structure .open_cate > .list_category {
  display: block;
}
.list_category {
  display: none;
  margin-left: 23px;
}
.list_category .btn_fold .ico_account {
  background-position: -100px -50px;
} /* 나머지 펼치기 전 + */
.list_category .open_cate > .btn_fold .ico_account {
  background-position: -120px -50px;
} /* 나머지 펼친 후 - */
</style>
