<template>
  <div class="add_groupinfo">
    <a href="javascript:void(0);" class="link_add" @click.prevent="addEmployee">
      <span class="ico_account">조직원 추가하기</span>
    </a>
    <div class="inner_box">
      <!-- 인풋과 함께 우측에 정렬될 때 : type_side 클래스 추가 -->
      <draggable
        id="groupPeople"
        class="group_people"
        ghost-class="drag_on"
        @start="drag = true"
        @end="drag = false"
      >
        <strong class="screen_out">선택된 담당자 목록</strong>
        <approval-comm v-if="typeMe" :emp="me" />
        <comm-item-emp
          v-for="(emp, index) in employeeList"
          :key="emp.empNo"
          :dataKey="emp.empNo + emp.actorFlag + emp.actorState + emp.apprLineFlag + emp.finalApprYn"
          :empName="emp.empName || emp.name"
          :accountId="emp.accountId"
          :isTypeMe="emp.typeMe"
          :itempPeopleStyle="emp.itempPeopleStyle"
          @remove-employee="removeEmployee(emp, index)"
        />
      </draggable>
      <!-- // group_people -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import CommItemEmp from "./CommItemEmp.vue";
import { default as CommDraggableMixin } from "@/_approval/mixins/common/commDraggable.js";
import { default as CommToastMixin } from "@/_approval/mixins/common/commToast.js";

Vue.prototype.$EventBus = new Vue();

export default {
  name: "CommOrgListbox",
  components: {
    CommItemEmp,
  },
  mixins: [CommDraggableMixin, CommToastMixin],
  props: {
    employeeList: Array,
    validateFunc: {
      type: Function,
      default: () => true,
    },
    isArrowDupliEmp: {
      type: Boolean,
      default: false,
    },
    spliceAddIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      type_one: "",
      searchType: "",
      typeMe: false,
      me: {},
      item: Object,
    };
  },
  created() {
    this.$EventBus.$on("tree-select-item", this.selectTreeItem);
  },
  methods: {
    selectTreeItem(item) {
      this.item = item;
    },
    addEmployee() {
      if (!this.validateFunc(this.item)) {
        return;
      }

      if (!this.isArrowDupliEmp && this.employeeList.some((emp) => emp.empNo === this.item.empNo)) {
        this._showToast({ content: "이미 선택된 사용자입니다." });
        return;
      }

      if (!this.item.empNo) {
        this._showToast({ content: "사용자를 선택해주세요." });
        return;
      }

      if (!this.item.isDept) {
        let emp = {};
        emp.empNo = this.item.empNo;
        emp.empName = this.item.empName;
        emp.accountId = this.item.accountId;
        emp.deptCode = this.item.deptCode;
        emp.deptName = this.item.deptName;
        // 중간 추가가 아닌경우
        if (this.spliceAddIndex == null) {
          this.employeeList.push(emp);
          // 중간 추가의 경우
        } else {
          this.employeeList.splice(this.spliceAddIndex, 0, emp);
        }
      } else {
        this._showToast({ content: "사용자를 선택해주세요." });
      }
    },
    removeEmployee(emp, i) {
      const obj = this;
      if (!this.isArrowDupliEmp) {
        this.employeeList.forEach((value, index) => {
          if (value.empNo === emp.empNo) {
            obj.employeeList.splice(index, 1);
          }
        });
      } else {
        this.employeeList.forEach((value, index) => {
          if (index === i) {
            obj.employeeList.splice(index, 1);
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.add_groupinfo {
  float: left;
  position: relative;
  margin: 46px 0 0 60px;
}
.add_groupinfo .link_add {
  position: absolute;
  top: 50%;
  left: -44px;
  width: 26px;
  height: 26px;
  border-radius: 2px;
  border: 1px solid #e1e1e1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.add_groupinfo .link_add .ico_account {
  width: 5px;
  height: 8px;
  margin: 9px auto 0;
  background-position: -150px -70px;
}
.add_groupinfo .inner_box {
  overflow-y: auto;
  width: 240px;
  height: 406px;
  padding: 0 10px 10px;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
}
</style>
